// This is imported in gatsby-ssr and gatsby-browser as wrapRootElement to provide a connection to the store.
import React from 'react'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import { AuthProvider } from '../../context/AuthContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const store = configureStore()

const queryClient = new QueryClient()

const Wrapper = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthProvider>
          {element}
        </AuthProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export default Wrapper
