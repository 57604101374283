import { useMemo } from "react"
import OktaAuth from '@okta/okta-auth-js';

export const TOKEN_STORAGE_KEY = 'marble-okta-token'

export const getConfig = () => {
  if (typeof window !== 'undefined') {
    return {
      clientId: '0oa1f3ut0aKpdwap5357',
      issuer: 'https://okta.nd.edu/oauth2/ausxosq06SDdaFNMB356',
      url: 'https://okta.nd.edu',
      redirectUri: `${window.location.origin}/user`,
      ignoreSignature: true,
      tokenManager: {
        secure: true,
        storage: 'localStorage',
        storageKey: TOKEN_STORAGE_KEY,
        autoRenew: true,
        autoRemove: true,

      },
      responseType: 'id_token',
      responseMode: 'fragment',
      pkce: true,
    }
  } else {
    return {}
  }
}

export const useAuthClient = () => {
  // The `OktaAuth` constructor can throw if the config is malformed
  const config = getConfig()

  const authClient = useMemo(() => {
    try {
      if (JSON.stringify(config) === '{}') {
        return null
      }

      const authClient = new OktaAuth(config);

      authClient
        .start()
        .then(() => {
          const tokenRenewListener = (key) => {
            authClient.tokenManager.renew(key);
          };

          authClient.tokenManager.on('expired', (k) => {
            tokenRenewListener(k)
          })
        })

      return authClient
    } catch (error) {
      console.error(`Auth client instantiation failed with error: ${error}`);
    }
  }, [])

  return authClient;
}

export const stubAuthClient = {
  token: {
    getWithRedirect: () => {},
  },
  tokenManager: {
    get: () => {}
  }
}