import React, { useCallback, useEffect, useState } from 'react'

import { navigate } from 'gatsby'
import { TOKEN_STORAGE_KEY, stubAuthClient, useAuthClient } from './authClient'

export const AUTH_STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
  LOGGED_IN: 'LOGGED_IN',
}

export const AuthContext = React.createContext({
  authClient: stubAuthClient,
  logIn: () => {},
  logOut: () => {},
  authStatus: AUTH_STATUS.LOADING,
  authData: undefined,
  getToken: () => Promise.resolve(null),
})

export const AuthProvider = ({ children, shouldAutofetchToken: _shouldAutofetchToken }) => {
  const [authData, setAuthData] = useState(undefined)
  const [authStatus, setAuthStatus] = useState(AUTH_STATUS.LOADING)
  const shouldAutofetchToken = !(_shouldAutofetchToken === false)

  const authClient = useAuthClient() || stubAuthClient

  const setLoggedOut = () => {
    setAuthData(null)
    setAuthStatus(AUTH_STATUS.NOT_LOGGED_IN)
  }

  const updateAuthData = (token) => {
    if (token && !authData) {
      const { name, email, netid } = token.claims
      setAuthData({ name, email, netid })
    }
  }

  const logIn = useCallback(() => {
    setAuthStatus(AUTH_STATUS.LOADING)
    // Save the user's requested url to local storage so we can redirect them after authenticating
    try {
      window.localStorage.setItem('redirectUrl', window.location.href)
    } catch (e) {
      console.warn('Local storage is not available.')
    }

    authClient.token.getWithRedirect({
      responseType: 'id_token',
      scopes: [
        'openid',
        'email',
        'profile',
        'netid',
        'directory',
      ],
      pkce: true,
    })
  }, [authClient?.token])

  const logOut = useCallback(() => {
    // remove the jwt login information in session storage
    localStorage.removeItem(TOKEN_STORAGE_KEY)
    setLoggedOut()
  }, [])

  const getToken = async () => {
    if (typeof window === 'undefined') {
      setLoggedOut()
      return null
    }

    try {
      const token = await authClient.tokenManager.get('idToken')

      if (token) {
        handleToken(token)

        return token.idToken

        // If ID Token isn't found, try to parse it from the current URL
      } else if (window.location.hash) {
        if (window.location.hash) {
          const response = await authClient.token.parseFromUrl()

          const { idToken } = response.tokens

          if (idToken) {
            authClient.tokenManager.add('idToken', idToken)

            handleToken(idToken)
            setAuthStatus(AUTH_STATUS.LOGGED_IN)
          }

          return idToken?.idToken || null
        }
      } else {
        setLoggedOut()
        return null
      }
    } catch (error) {
      console.error(`finding token failed with error: ${error}`)
      setAuthData(null)
      setAuthStatus(AUTH_STATUS.ERROR)
    }

    return null
  }

  const handleToken = (token) => {
    updateAuthData(token)

    try {
      const redirectUrl = window.localStorage.getItem('redirectUrl')

      if (redirectUrl) {
        // Remove it so that subsequent requests for the token do not cause redirects
        window.localStorage.removeItem('redirectUrl')
      }

      // Now redirect the browser if the redirectUrl is different from the current url
      if (redirectUrl && redirectUrl !== window.location.href) {
        window.location.assign(redirectUrl)
      }
    } catch (e) {
      console.warn('Session storage is not available.')
    }
  }

  useEffect(() => {
    shouldAutofetchToken && getToken()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider value={{
      authClient,
      logIn,
      logOut,
      authData,
      getToken,
      authStatus,
    }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => React.useContext(AuthContext)

export const useLeavePageOnLogOut = () => {
  const { authData } = useAuth()

  useEffect(() => {
    if (authData === null) {
      navigate('/')
    }
  })
}
